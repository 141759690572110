import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
<footer>
    <p>&copy; 2021 - leonardbauling.com</p>
</footer>
    )
}

export default Footer;