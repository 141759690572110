import React, { useEffect } from 'react';
import MessageProps from '../common/MessageProps';
import gsap from 'gsap';
import './Message.css'

const Message = (props: MessageProps) => {
    const containerClass = `message-container ${props.type}`;
    const cards = ["2C","3D","4H","5S","6S","7H","8D","9C","10D","AC","Back1","JH","QS","XR"];
    useEffect(() => {
        if (props.type === "async") {

            let w = Number(gsap.getProperty(".message-container", "width")) - 32;
            var tl = gsap.timeline({
                repeat: -1, 
                yoyo: true
              });
            tl.to(".async-item", { duration: 2, left: w, repeat: -1, stagger: 0.2, ease: "circ" });

        }
    });
    const cardPath = () => {
        let randomIndex = Math.floor(Math.random() * cards.length);
        return `../images/plcards/${cards[randomIndex]}.svg`;
    }

    return (
<div className={containerClass}>
    {props.heading ? <h3>{props.heading}</h3>: ""}
    <p>{props.message}</p>
    {props.type === "async" ?
    <div className="async-container">
        <img className="async-item" src={cardPath()} alt="play card 1" />
        <img className="async-item" src={cardPath()} alt="play card 2" />
        <img className="async-item" src={cardPath()} alt="play card 3" />
        <img className="async-item" src={cardPath()} alt="play card 4" />
        <img className="async-item" src={cardPath()} alt="play card 5" />
        <img className="async-item" src={cardPath()} alt="play card 6" />
        <img className="async-item" src={cardPath()} alt="play card 7" />
    </div> : ""}
</div>
    );
};



export default Message;