import React from 'react';
import { NavLink } from 'react-router-dom';
import './Card.css';
import CardProps from '../common/CardProps';
import Message from './Message';

const Card = (props: CardProps) => {
    return (
<section className="card-container">
    <div className="card">
        <div className="card-icon">
            <img src={props.imagePath} alt={props.imageDescription} />
        </div>
        <div className="card-detail">
            <div className="card-info">
                <h2>{props.heading}</h2>
                <p>{props.description}</p>
            </div>
            {   props.isActive || props.user.roles.findIndex(role => role === "Admin") > -1 ? 
                <button className="default-button">
                    <NavLink className="card-link" to={props.linkTo} exact>{props.linkToLabel}</NavLink>
                </button> :
                <Message heading="Not available" message={props.inActiveNotice}
                type="info" />
            }
        </div>
    </div>
</section>
    );
}

export default Card;