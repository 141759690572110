import React, {useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import './App.css';
import Settings from './common/Settings';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Games from './pages/Games';
import Login from './components/Login';
import LoginProps from './common/LoginProps';
import NavbarProps from './common/NavbarProps';
//import HomeProps from './common/HomeProps';
import { User } from './common/Model';
import { useCookies } from 'react-cookie';
//import { getRoles } from '@testing-library/react';
//import { updateLanguageServiceSourceFile } from 'typescript';

function App() {
  const [cookies, setCookie, removeCookie] = useCookies(["cookieUser"]);
  const initialUser:User = { username: '', email: '',iconPath: '', token: '', roles: ['Player'] };    
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [mustRemember, setMustRemember] = useState(true);
  const [user, setUser] = useState<User>(initialUser);
  const [showProfileContextMenu, setShowProfileContextMenu] = useState(false);
 
  const settings:Settings = {
    //apiBaseAddress: "http://localhost/api/", //Test
    apiBaseAddress: "http://api.leonardbauling.com/", //Prod
    showProfileContextMenu: showProfileContextMenu, 
    UpdateShowProfileContextMenu: (value: boolean) => {
      setShowProfileContextMenu(value);
    }
  }

  const loginProps: LoginProps = {
    CloseLoginForm: () => {
      setIsOpen(false);
    },
    RememberMeChanged: (e) => {
      setMustRemember(e.target.checked);
    },
    UpdateUser: (loggedInUser) => {
      if (mustRemember) {
        setCookie("cookieUser", loggedInUser, {path: '/'} );
      }
      else {
        removeCookie("cookieUser");
      }
      setIsLoggedIn(true);
      setUser(loggedInUser);
      setIsOpen(false);
    },
    isLoggedIn: isLoggedIn, user: user,
    settings: settings
  }
  const navbarProps:NavbarProps = {
    user: user, isLoggedIn: false,
    OpenLoginForm: (e) => {
      setIsOpen(true);
    },
    SignOut: (e) => {
      setIsLoggedIn(false);
      setUser(initialUser);
      removeCookie("cookieUser");
    },
    settings: settings
  };

  useEffect(() => {
    if (!isLoggedIn && cookies.cookieUser) {
        loginProps.UpdateUser(cookies.cookieUser);
    }
  });

  return (
    <Router>
      <section className="page-main">
        <header className="page-header">
          <Navbar  user={user} isLoggedIn={isLoggedIn} OpenLoginForm={navbarProps.OpenLoginForm} SignOut={navbarProps.SignOut} 
          settings={navbarProps.settings} />
        </header>
        <main className="page-body">
          {isOpen ? <Login user={user} 
            isLoggedIn={isLoggedIn}
            CloseLoginForm={loginProps.CloseLoginForm}
            RememberMeChanged={loginProps.RememberMeChanged}
            UpdateUser={loginProps.UpdateUser} settings={settings} /> :
            <Switch>
              <Route path="/" exact>
                <Home User={user} Settings={settings} />
              </Route>
              <Route path="/games/:token" exact>
                <Games user={user} isLoggedIn={isLoggedIn} settings={settings} />
              </Route>
              <Redirect to="/" />
            </Switch>
          }
        </main>
        <footer className="page-footer">
          <Footer />
        </footer>
      </section>
    </Router>
  );
}

export default App;