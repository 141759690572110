import React from 'react';
import './Home.css';
import Card from '../components/Card';
import CardProps from '../common/CardProps';
import HomeProps from '../common/HomeProps';

const Home = (props:HomeProps) => {
    const gamesProp: CardProps = {
        imagePath:"images/joystick-128.png",
        imageDescription: "Joystick icon for Games card",
        heading: "Games",
        description: "Head over to our Games section to see some of our awesome games!",
        linkTo: "/games/all",
        linkToLabel: "Show Games",
        isActive: true,
        inActiveNotice: "",
        user: props.User
    };
    const removeContextMenu:React.MouseEventHandler<HTMLSpanElement> = (e:any) => {
        props.Settings.UpdateShowProfileContextMenu(false);
    };
    return (
<div className="page-container home-container" onClick={removeContextMenu}>
    <div className="cards-shortcuts">
        <Card imagePath={gamesProp.imagePath} imageDescription={gamesProp.imageDescription}
            heading={gamesProp.heading} description={gamesProp.description} linkTo={gamesProp.linkTo}
            linkToLabel={gamesProp.linkToLabel} isActive={true} inActiveNotice="" user={gamesProp.user} />
    </div>
</div>
    );
}

export default Home;