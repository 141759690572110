import React from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css';
import NavbarProps from '../common/NavbarProps';

const Navbar = (props: NavbarProps) =>
{
    const signIn:React.MouseEventHandler<HTMLSpanElement> = (e:any) => {
        props.OpenLoginForm(e);
        props.settings.UpdateShowProfileContextMenu(false);
    };
    const signOut:React.MouseEventHandler<HTMLSpanElement> = (e:any) => {
        props.SignOut(e);
    };
    const toggleContext:React.MouseEventHandler<HTMLSpanElement> = (e:any) => {
        props.settings.UpdateShowProfileContextMenu(!props.settings.showProfileContextMenu);
    };
    return (
<div>
<nav>
    <NavLink className="logo-link" to="/" exact>V.BG</NavLink>
    <ul className="nav-links">
        <li><NavLink className="nav-link" to="/games/all" exact>Games</NavLink></li>
        {props.isLoggedIn ? 
        <div className="nav-dropdown">
            <button onClick={toggleContext}>
                <img className="nav-link profile-image" src={`../images/icons/${props.user.iconPath}`} alt="Player's icon" />
            </button>
            { props.settings.showProfileContextMenu ?
                <div className="nav-dropdown-content display-block">
                    <button onClick={signOut} >Sign out</button> 
                </div>:
                <div className="nav-dropdown-content">
                    <button onClick={signOut} >Sign out</button> 
                </div>
            }
        </div>:
        <li><button className="nav-link" onClick={signIn} >Sign In</button></li>}
    </ul>
</nav>
</div>
    );
}

export default Navbar;