import React, { useState } from 'react';
import { useAsyncEffect } from 'use-async-effect';
import './Games.css';
import { useParams } from 'react-router-dom';
import GamesProps from '../common/GamesProps';
import Message from '../components/Message';
import Card from '../components/Card';

const Games = (props:GamesProps) => {
    const apiPath = `${props.settings.apiBaseAddress}GetData.php?`;
    const tokenParam = useParams<{token: string}>();

    const [token, setToken] = useState(tokenParam.token);
    const [isBusy, setIsBusy] = useState(false);
    const [message, setMessage] = useState({
        message: "",
        heading: "",
        type: "info"
    });
    const [couldNotRetrieveGames, setCouldNotRetrieveGames] = useState(false);
    const [games, setGames] = useState<any[]>([]);

    if (props.isLoggedIn && (token === undefined || token === "")) {
        setToken(props.user.token)
    };

    useAsyncEffect(async isMounted => {
        if (!isBusy && games.length === 0 ) {
            setIsBusy(true);
            try {
                const res = await fetch(`${apiPath}method=Game_Catalog`);
                if (!res.ok) {
                    setMessage({
                        message: `⚠️${res.statusText}`,
                        heading: "Well, this is unexpected.",
                        type: "warn"
                    });
                    setIsBusy(false);
                }
                const jsonResponse = await res.json();
                if (jsonResponse !== undefined && jsonResponse !== null && jsonResponse.length > 0) {
                    setGames(jsonResponse);
                    setCouldNotRetrieveGames(false);
                    setIsBusy(false);
                }
                else {
                    setCouldNotRetrieveGames(true);
                    setMessage({
                        message: "😧Could not retrieve games",
                        heading: "No games found",
                        type: "warn"
                    });
                    setIsBusy(false);
                }    
            }
            catch(e) {
                setCouldNotRetrieveGames(true);
                setMessage({
                    message: `⚠️ ${e}.`,
                    heading: "Well, this is unexpected.",
                    type: "error"
                });
                setIsBusy(false);
            }
        }
    },[games]);
    return (
<div className="page-container games-container">
{isBusy ? <div className="message-loader"><Message heading="Retrieving Games" message="⏳Retrieving games from the server. Stand by..." type="async" /></div> : "" }
{couldNotRetrieveGames ? <Message heading={message.heading} message={message.message}
    type={message.type} />: ""}
{games.length > 0 ? <div className="games-cards-shortcuts">
    {games.map(game => <Card key={game[0]} imagePath={`../images/icons/${game[3]}`} imageDescription={`${game[1]} icon`}
            heading={game[1]} description={`${game[2].substring(0,250)}...`} linkTo={`./Game/${game[0]}`} linkToLabel="Show Game"
            isActive={game[5] === "Active"} inActiveNotice={`Game is not active yet - Currently: ${game[5]}`} user={props.user} />)}
    </div> : <div>No games found</div>} 
</div>
    );
}

export default Games;