import React, {useState } from 'react';
import './Login.css'; 
import Message from '../components/Message';
import LoginProps from '../common/LoginProps';
import { useForm } from '../hooks/useForm';

 const Login = (props: LoginProps) => {

    const initialState = {
        username: "",
        password: ""
    }

    const [isBusy, setIsBusy] = useState(false);
    const { onChange, onSubmit, values } = useForm( loginUserCallback, initialState );
    const [couldNotSignIn, setCouldNotSignIn] = useState(false);
    const [message, setMessage] = useState({
        message: "",
        heading: "",
        type: "info"
    });
    const [rememberMe, setRememberMe] = useState(true);

    let apiPath = `${props.settings.apiBaseAddress}GetPlayer.php?`;

    async function loginUserCallback() {
        setCouldNotSignIn(false);
        setIsBusy(true);

        const username = JSON.parse(JSON.stringify(values)).username;
        let password = JSON.parse(JSON.stringify(values)).password;
        try {
            const res = await fetch(`${apiPath}player_name=${username}&player_password=${password}`);
            if (!res.ok) {
                setCouldNotSignIn(true);
                setMessage({
                    message: `⚠️${res.statusText}`,
                    heading: "Well, this is unexpected.",
                    type: "warn"
                });
                setIsBusy(false);
            }
            const loginResponse = await res.json();

            if (loginResponse !== undefined && loginResponse !== null && loginResponse.length > 0) {
                props.UpdateUser({"username": loginResponse[0].Name, "email": loginResponse[0].EMail,
                    "token": loginResponse[0].Token, "iconPath": loginResponse[0].Icon_Path,
                    "roles": loginResponse[0].Role.replace('[','').replace(']','').split(',')});
                setCouldNotSignIn(false);
            }
            else {
                setCouldNotSignIn(true);
                setMessage({
                    message: "😧Could not sign you in",
                    heading: "Login failed",
                    type: "warn"
                });
                setIsBusy(false);
            }    
        }
        catch(e) {
            setCouldNotSignIn(true);
            setMessage({
                message: `⚠️ ${e}.`,
                heading: "Well, this is unexpected.",
                type: "error"
            });
            setIsBusy(false);
        }
        
    }

    const rememberClick = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRememberMe(e.target.checked);
        props.RememberMeChanged(e);
    };
    const closeClick: React.MouseEventHandler<HTMLSpanElement> = (e:any) => {
        props.CloseLoginForm(e);
    };

    return (
<div className="login-modal page-container">
    <span className="login-close" title="Close Login form" onClick={closeClick}>x</span>
    <form className="login-content" onSubmit={onSubmit}>
        <div className="login-image">
            <img src="../images/login-avatar_640.png" alt="Login avatar"/>
        </div>
        <div className="login-container">
            <label htmlFor="username"><b/>Username</label>
            <input id="username" name="username" type="text" placeholder="Enter username" onChange={onChange} required/>
            <label htmlFor="password"><b/>Password</label>
            <input id="password" name="password" type="password" placeholder="Enter password" onChange={onChange} required/>
            {couldNotSignIn? <Message heading={message.heading} message={message.message}
                type={message.type} />: ""}
            {isBusy ? <Message heading="Login request" message="⏳Checking on the server. Stand by..." type="async" /> :
                <button className="default-button" type="submit">Login</button>
            }
            <label><input id="remember" type="checkbox" checked={rememberMe} onChange={rememberClick}/> Remember Me</label>
        </div>
    </form>
</div>
    );
 }

 export default Login;